export default [
    {
        header: 'GENERAL',
    },
    {
        title: 'Dashboard',
        route: 'home',
        icon: 'HomeIcon',
    },
    {
        header: 'CONTENT MANAGEMENT',
    },
    {
        title: 'Categories',
        route: 'cms-category-list',
        icon: 'GridIcon',
    },
    {
        title: 'Content',
        route: 'cms-content-list',
        icon: 'FileTextIcon',
    },
    {
        title: 'Menu',
        route: 'cms-menu-list',
        icon: 'MenuIcon',
    },
    {
        title: 'Sliders',
        route: 'cms-slider-list',
        icon: 'BookIcon',
    },
    {
        title: 'Language',
        route: 'cms-language-list',
        icon: 'GlobeIcon',
    },
    {
        title: 'Media Files',
        route: 'cms-media',
        icon: 'ImageIcon',
    },
    {
        title: 'Projects',
        route: 'cms-project-list',
        icon: 'FolderIcon',
    },
    {
        title: 'Settings',
        route: 'cms-settings',
        icon: 'SettingsIcon',
    },
    {
        title: 'Forms',
        route: 'cms-forms',
        icon: 'TabletIcon',
    },
    {
        header: 'DEFINITION MANAGEMENT',
    },
    {
        route: 'definition-feature-list',
        title: 'Features',
        icon: 'ListIcon',
    },
    {
        route: 'definition-space-list',
        title: 'Spaces',
        icon: 'ListIcon',
    },
    {
        header: 'MEMBER MANAGEMENT',
    },
    {
        route: 'member-list',
        title: 'Members',
        icon: 'UsersIcon',
    },
    {
        header: 'USER MANAGEMENT',
    },
    {
        route: 'system-role-list',
        title: 'Roles',
        icon: 'LockIcon',
    },
    {
        route: 'system-user-list',
        title: 'Users',
        icon: 'UsersIcon',
    },
]
